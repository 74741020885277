import request from '@/utils/request'
export function listAll () {
  return request({
    url: '/newWord/listAll',
    method: 'POST'
  })
}
export function delWord (data) {
  return request({
    url: '/newWord/delWord',
    method: 'POST',
    data
  })
}
export function addWord (data) {
  return request({
    url: '/newWord/addWord',
    method: 'POST',
    data
  })
}
