<template>
  <section class="newword-wrap">
    <!-- <side-bar/>
    <section class="push-content"> -->
    <!-- </section> -->
    <section class="content" v-loading="loading">
      <template>
      <div v-for="ms in data" class="card">
        <p style="font-size: 20px">{{ ms.msName }}</p>
        <span v-for="newWord in ms.words">
          <el-popconfirm cancelButtonText='忽略' title="是否将该词添加至所属监测主题下？" @onCancel="delNewWord(newWord.id)" @onConfirm="conf(ms.msId, newWord.word, newWord.id)">
            <el-button slot="reference" style="border-radius: 20px;margin-right: 20px;margin-top: 15px">{{ newWord.word }}</el-button>
          </el-popconfirm>
        </span>
      </div>
      </template>
    </section>
  </section>
</template>

<script>
import SideBar from '@/components/SideBar'
import { listAll, delWord, addWord } from '@/api/settings/newword'
export default {
  data () {
    return ({
      data: [],
      loading: true
    })
  },
  components: {
    SideBar
  },
  mounted () {
    this.listAllWord()
  },
  methods: {
    async conf (msId, word, id) {
      this.loading = true
      const res = await addWord({ msId: msId, word: word })
      if (res.code === 0) {
        this.$message.success('添加成功')
        await delWord({ id: id })
        this.listAllWord()
      } else {
        this.$message.error('添加失败')
      }
    },
    async listAllWord () {
      const res = await listAll()
      this.data = res.data
      this.loading = false
    },
    async delNewWord (id) {
      this.loading = true
      const res = await delWord({ id: id })
      if (res.code === 0) {
        this.$message.success('忽略成功')
        this.listAllWord()
      } else {
        this.$message.error('移除失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/index';
  .newword-wrap {
    width: 100%;
    height: 100%;
    /* padding: 30px 30px;
    display: flex;
    .push-content {
      width: 100%;
      min-height: $contentHeight;
      margin-left: 30px;
    } */
    .content {
      width: 100%;
      height: 100%;
      min-height: calc(100% - 90px);
      // margin-top: 20px;
      border-radius: 2px;
      box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
      // padding: 20px 30px;
      position: relative;
    }
    .card{
      width: 48%;
      height: 320px;
      float: left;
      background: #fff;
      margin: 10px;
      padding: 10px;
      border-radius: 16px;
      padding-bottom: 40px;
    }
  }
</style>
